import React from 'react';
import MenuButton from '../MenuButton/MenuButton';


class LeftMenu extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return(
        <div className="grid grid-cols-1 border-r-[1px]">
            <MenuButton extra=" py-1 pl-4 bg-black/50 text-[14px] font-bold bg-[url('../images/Buttons/about.jpg')] bg-no-repeat h-[31px]" link="/about" />
            <MenuButton extra=" py-1 pl-4 bg-black/50 text-[14px] font-bold bg-[url('../images/Buttons/company-overview.jpg')] bg-no-repeat h-[30px]" link="/overview" />
            <MenuButton extra=" py-1 pl-4 bg-black/50 text-[14px] font-bold bg-[url('../images/Buttons/production-facilities.jpg')] h-[31px]" link="/production"/>
            <MenuButton extra=" py-1 pl-4 bg-black/50 text-[14px] font-bold bg-[url('../images/Buttons/mission.jpg')] h-[30px]" link="/our-mission"/>
            <MenuButton extra=" py-1 pl-4 bg-black/50 text-[14px] font-bold bg-[url('../images/Buttons/core-values.jpg')] h-[30.5px]" link="/values"/>
            <MenuButton extra=" py-1 pl-4 bg-black/50 text-[14px] font-bold bg-[url('../images/Buttons/products.jpg')] h-[31px]" link="/products"/>
            <MenuButton extra=" py-1 pl-4 bg-black/50 text-[14px] font-bold bg-[url('../images/Buttons/quality.jpg')] h-[31px]" link="/approvals"/>
        </div>
      );
    }
  }

  export default LeftMenu;
