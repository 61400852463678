import React from 'react';
import { Link } from "gatsby"
import './MenuButton.css';


class MenuButton extends React.Component {
    constructor(props) {
      super(props);
    }

    render() {

      return(
        <Link className={["menu-button relative col-span-1 border-r-1 border-b-1", this.props.extra]} to={this.props.link}>{this.props.title}</Link>
      );
    }
  }

  export default MenuButton;
