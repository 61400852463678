import React from 'react';
import MenuButton from '../MenuButton/MenuButton';


class TopMenu extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return(
        <div className="grid grid-cols-6 border-b-[1px]">
            <MenuButton extra=" text-xs pl-2 p-[3px] pb-[4px] font-normal border-r-[1px] bg-[#0d2e2e] menu-small" title="Home" link="/" />
            <MenuButton extra=" text-xs pl-2 p-[3px] pb-[4px] font-normal border-r-[1px] bg-[#0d2e2e] menu-small" title="Contact us" link="/contact" />
            <div className=" col-span-1 border-r-1 border-b-1 text-xs pl-2 p-[3px] pb-[4px] font-normal border-r-[1px] bg-[#0d2e2e]"/>
            <div className=" col-span-1 border-r-1 border-b-1 text-xs pl-2 p-[3px] pb-[4px] font-normal border-r-[1px] bg-[#0d2e2e]"/>
            <div className=" col-span-1 border-r-1 border-b-1 text-xs pl-2 p-[3px] pb-[4px] font-normal border-r-[1px] bg-[#0d2e2e]"/>
            <div className=" col-span-1 border-b-1 text-xs pl-2 p-[3px] pb-[4px] font-normal bg-[#0d2e2e]"/>
        </div>
      );
    }
  }

  export default TopMenu;
