import React from 'react';
import Seo from '../SEO/Seo'

class Header extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return(
        <header className="bg-[url('../images/Layout/header.jpg')] bg-no-repeat w-full h-[124px]"><Seo></Seo></header>
      );
    }
  }

  export default Header;
