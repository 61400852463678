import React from 'react';
import MSI from '../../images/MenuImage/msi.jpg';
import Forge from '../../images/MenuImage/quality-forging-large.jpg';

class MenuImage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {isHome: false};
    }

    componentDidMount() {
        let Urlname = window.location.pathname;

        if (Urlname == '/') {
            this.setState({isHome: true});
        }
    }

    render() {
     const isHome = this.state.isHome;
      return(
        <>
            { isHome ?
            <img src={MSI} alt="Msi logo" />
            : <img src={Forge} alt="Metal being forged" />
            }
        </>
      );
    }
  }

  export default MenuImage;
