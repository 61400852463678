 import React from "react"
 import { Helmet } from "react-helmet"
 
 function SEO() {
 
   return (
     <Helmet
       title='MSI-Quality Forgings Ltd'
       meta={[
         {
           name: `description`,
           content: `MSI-Quality Forgings Ltd`,
         },
         {
           name: `keywords`,
           content: `MSI-Quality Forgings Ltd`,
         },
         {
           property: `og:title`,
           content: `MSI-Quality Forgings Ltd`,
         },
         {
           property: `og:description`,
           content: `MSI-Quality Forgings Ltd`,
         },
         {
           property: `og:type`,
           content: `website`,
         },
         {
           name: `twitter:card`,
           content: `summary`,
         },
         {
           name: `twitter:title`,
           content: `MSI-Quality Forgings Ltd`,
         },
         {
           name: `twitter:description`,
           content: `MSI-Quality Forgings Ltd`,
         },
       ].concat()}
     />
   )
 }
 
 export default SEO
 